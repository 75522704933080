import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { useRedirect } from "../../hooks/useRedirect";

const RedirectPage: React.FC<PageProps<any>> = ({ data }) => {
  const {
    wpPage: {
      template: {
        redirectTemplateP: { redirectUrl },
      },
    },
  } = data;
  useRedirect(redirectUrl);
  return null;
};

export default RedirectPage;

export const query = graphql`
  query RedirectTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      template {
        ... on WpRedirectTemplate {
          templateName
          redirectTemplateP {
            redirectUrl
          }
        }
      }
    }
  }
`;
